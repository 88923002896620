import React, { useCallback, useState } from 'react';
import { Formik, Field } from 'formik';
import {
  defaultInternationalization,
  priorityInternationalization,
} from 'corporate-types';
import { useRouter } from 'next/router';

import Autocomplete, { ListSearch } from '../autocomplete/Autocomplete';
import ModalWrapper from '../modal/modal-wrapper/ModalWrapper';
import { ModalSize, ModalStyleType } from '../../models/enum';
import { SingleStrapiImage } from '../../models/domain/interfaces';
import { getSearchResults, getSectorUI } from '../../utils/api';
import { getStrapiMedia } from '../../utils/media';
import {
  hexToRgb,
  internationalizationToLanguages,
  rgbDataURL,
} from 'corporate-utils';
import { urlFromCategoriesIds } from '../../utils/urls';

import {
  BodyWrapper,
  HeaderContentWrapper,
  ImageWrapper,
  ModalContentWrapper,
  StyledForm,
  TransaltionTestStyled,
} from './SearchModal.style';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LocalizedPaths } from '../../models/domain/type';
import { DEFAULT_IMAGE } from '../../models/domain/const';
import ImageWithFallback from '../image-with-fallback/ImageWithFallback';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {
  callApiToVerifyCaptcha,
  getUseAlternativeCaptcha,
} from '../../utils/captcha';

interface IProps {
  open: boolean;
  handleClose: () => void;
  logo: SingleStrapiImage;
  translations: any;
  localizedPaths: LocalizedPaths;
  locale: string;
  megamenuLinks: any;
  userCountry: string;
  isPrerenderRequest: boolean;
}

type SearchProps = IProps;

interface ResultSearch {
  id: string;
  type: string;
  name: string;
  nameLanguage: any;
  children: string[];
  tags: string[];
  technical_data: string[];
  parents?: number[];
  categoriesCalculated?: number[];
}

const SearchModal: React.FC<SearchProps> = ({
  open,
  handleClose,
  logo,
  translations,
  megamenuLinks,
  locale,
  userCountry,
  isPrerenderRequest,
}) => {
  const [search, setSearch] = useState('');
  const [sectorPath, setSectorPath] = useState<{ [key: string]: string }>({});
  const [result, setResult] = useState<ListSearch | undefined>(undefined);
  const [request, setRequest] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const { query } = router;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const hCaptchaSearchRef = React.useRef<HCaptcha>(null);
  const useAlternativeCaptcha = getUseAlternativeCaptcha(userCountry);

  const downloadSectorPath = useCallback(
    async (resTransformed: ListSearch): Promise<void> => {
      if (resTransformed['sector']) {
        const onlySector = resTransformed['sector'];

        const promiseAll = await Promise.all(
          onlySector.map(async ({ value }) => {
            return (
              sectorPath[
                `${value}_${router?.locale || defaultInternationalization}`
              ] ||
              (await getSectorUI({
                idDwh: parseInt(value),
                locale: router?.locale || defaultInternationalization,
              }))
            );
          })
        );

        const copy = { ...sectorPath };

        promiseAll.forEach((result: any) => {
          const { attributes } = result || {};
          const { slug, idDwh } = attributes || {};

          if (slug && idDwh && router?.locale && !copy[idDwh]) {
            copy[`${idDwh}_${router?.locale || defaultInternationalization}`] =
              slug;
          }
        });
        setSectorPath(copy);
      }
    },
    [router?.locale, sectorPath]
  );

  const searchTransform = (results: ResultSearch[]): ListSearch => {
    const list: ListSearch = {};
    for (const result of results) {
      if (!list[result.type]) list[result.type] = [];
      if (result.type === 'category' || result.type === 'sector') {
        const text =
          router.locale !== 'default'
            ? result.nameLanguage[
                internationalizationToLanguages(
                  (router.locale as string) || defaultInternationalization,
                  router?.asPath as string
                ) as string
            ] ||
              result.nameLanguage[
                internationalizationToLanguages(
                  priorityInternationalization[0] ||
                    defaultInternationalization,
                  router?.asPath as string
                ) as string
              ] ||
              result.nameLanguage[
                internationalizationToLanguages(
                  priorityInternationalization[1] ||
                    defaultInternationalization,
                  router?.asPath as string
                ) as string
              ]
            : result.nameLanguage[
                internationalizationToLanguages(
                  defaultInternationalization,
                  router?.asPath as string
                ) as string
            ];
        list[result.type].push({
          text,
          value: result.id,
          parents: result.parents,
        });
      } else
        list[result.type].push({
          text: result.name,
          value: result.id,
          parents: result.parents,
        });
    }
    return list;
  };

  const getSearch = async (search: string): Promise<void> => {
    setIsLoading(true);
    if (search.length >= 3) {
      const token = (await executeRecaptcha?.('search')) || '';

      if (token) {
        const res = await getSearchResults(
          search,
          {
            token,
            alternativeCaptcha: useAlternativeCaptcha,
          },
          locale
        );
        const resTransformed = searchTransform(res);
        setResult(resTransformed);
        downloadSectorPath(resTransformed);
      }
    } else {
      setResult(undefined);
    }
    setIsLoading(false);
  };

  const updateAutocomplete = async (search: string): Promise<void> => {
    setSearch(search);
    if (search.length >= 3 && !useAlternativeCaptcha) {
      if (request) {
        clearTimeout(request);
      }
      setRequest(setTimeout(() => getSearch(search), 300));
    } else {
      setResult(undefined);
    }
  };

  const verifyHCaptchaToken = async (): Promise<boolean> => {
    try {
      const token = await hCaptchaSearchRef?.current
        ?.execute({ async: true })
        .then(({ response }) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
        });

      const res = await callApiToVerifyCaptcha(
        token as string,
        useAlternativeCaptcha,
        locale
      );

      return res.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const onClickSuggestion = async (
    value: string,
    type: string,
    parents: number[],
    text: string
  ): Promise<void> => {
    let userVerified: boolean;
    switch (type) {
      case 'product': {
        let url = urlFromCategoriesIds(
          megamenuLinks,
          parents,
          router.locale,
          'product',
          { sku: value, nameFather: text },
          'packages/corporate-ui/src/components/search-modal/SearchModal.tsx 1'
        );
        if (!url) url = '/';
        router.push(
          {
            pathname: url,
          },
          {
            pathname: url,
          },
          { locale: router.locale }
        );
        break;
      }
      case 'category': {
        let url = urlFromCategoriesIds(
          megamenuLinks,
          parents,
          router.locale,
          'category',
          undefined,
          'packages/corporate-ui/src/components/search-modal/SearchModal.tsx 2'
        );
        if (!url) url = '/';
        if (parents.length > 2)
          query[translations?.queryParmas?.subcategory] =
            '' + parents[parents.length - 1];
        delete query?.slug;
        router.push(
          {
            pathname: url,
            query: url != '/' ? query : null,
          },
          {
            pathname: url,
            query: url != '/' ? query : null,
          },
          { locale: router.locale }
        );
        break;
      }
      case 'sector':
        router.push(
          {
            pathname:
              sectorPath[
                `${value}_${router?.locale || defaultInternationalization}`
              ],
          },
          {
            pathname:
              sectorPath[
                `${value}_${router?.locale || defaultInternationalization}`
              ],
          },
          { locale: router.locale }
        );
        break;
      case 'manualSearch':
        userVerified = useAlternativeCaptcha
          ? await verifyHCaptchaToken()
          : true;
        userVerified &&
          router.push(
            {
              pathname: translations.urls.searchProduct,
              query: { q: value },
            },
            {
              pathname: translations.urls.searchProduct,
              query: { q: value },
            },
            { locale: router.locale }
          );
        break;
      default:
        break;
    }
    setSearch('');
    handleClose();
  };

  return (
    <ModalWrapper
      show={open}
      hideModal={handleClose}
      size={ModalSize.BIG}
      showDefaultHeader={false}
      styleType={ModalStyleType.localizations}
    >
      <ModalContentWrapper>
        <ModalWrapper.Header typeHeader="content" onClose={handleClose}>
          <HeaderContentWrapper>
            <ImageWrapper>
              <ImageWithFallback
                layout="fill"
                objectFit="contain"
                objectPosition="left"
                placeholder="blur"
                blurDataURL={rgbDataURL(
                  hexToRgb('ffffff')?.[0],
                  hexToRgb('ffffff')?.[1],
                  hexToRgb('ffffff')?.[2]
                )}
                src={getStrapiMedia(logo?.data?.attributes?.url ?? '')}
                alt={logo?.data?.attributes?.alternativeText}
                fallbackSrc={DEFAULT_IMAGE}
                isPrerenderRequest={isPrerenderRequest}
              />
            </ImageWrapper>
          </HeaderContentWrapper>
        </ModalWrapper.Header>
        <ModalWrapper.Body styleType={ModalStyleType.localizations}>
          <BodyWrapper>
            <TransaltionTestStyled>
              {translations?.mobileMenu?.search}
            </TransaltionTestStyled>
            <Formik
              initialValues={{
                category: '',
                search: '',
              }}
              onSubmit={() => {}}
            >
              {(): React.ReactNode => (
                <StyledForm>
                  <Field
                    name="search"
                    component={Autocomplete}
                    updateSearch={updateAutocomplete}
                    search={search}
                    loading={isLoading}
                    results={result}
                    placeholder={
                      translations?.components?.shell?.header?.search
                    }
                    onClickSuggestion={onClickSuggestion}
                    useAlternativeHint={useAlternativeCaptcha}
                  />
                  {useAlternativeCaptcha && (
                    <HCaptcha
                      sitekey={process.env.NEXT_PUBLIC_H_CAPTCHA_SITE_KEY || ''}
                      size="invisible"
                      ref={hCaptchaSearchRef}
                      reCaptchaCompat={false}
                    />
                  )}
                </StyledForm>
              )}
            </Formik>
          </BodyWrapper>
        </ModalWrapper.Body>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export default SearchModal;
